<template>
	<div>

		<form class="payment-form" v-show="activetab == 'form'">
			<!--p align="right"><a href="/" class="text-primary" style="color:#005daa;">[GO BACK]</a></p-->
			<p align="center"><img alt="Rotary District 7030 Logo logo" class="logo"
					src="../assets/rotaract-7030-logo.png">
			</p>
			<h2 align="center">Rotaract District 7030 Dues Payment Form</h2>
			<select class="form-control" v-model="clubname" @change="clubnameChange">
				<option value="0" selected="true" disabled>--- Select your club ---</option>
				<option v-for="(club, index) in clubs" :key="index"
					:value="{ id: club.id, clubname: club.data.clubname, size: club.data.clubsize, amt: club.data.dues, paid: club.data.paid, emails: club.data.emails, charge: club.data.charge, arrears: club.data.arrears, clubtype: club.data.clubtype, current: club.data.current, pins: club.data.pins }"
					:disabled="club.data.dues < 1">{{ club.data.clubname }}</option>
			</select>
			<p style="margin-bottom:40px;">&nbsp;</p>
			<div v-show="clubname != 0 && !showstripe">
				<div>
					<h2>Club Information</h2>
				</div>
				<table class="summary-table">
					<tr>
						<td>Number of members in your club </td>
						<td align="right">{{ Number(clubname.size) > 0 ? clubname.size : "TBA" }}</td>
					</tr>
					<tr>
						<td>Club type </td>
						<td align="right">{{ clubname.clubtype }}</td>
					</tr>
					<tr>
						<td>Per Capita Charges (USD) </td>
						<td align="right">${{ clubname.charge | currency }}</td>
					</tr>
					<tr>
						<td>District Dues (USD) </td>
						<td align="right">${{ clubname.current | currency }}</td>
					</tr>
					<tr>
						<td>BBF / Arrears (USD) </td>
						<td align="right">${{ clubname.arrears | currency }}</td>
					</tr>
					<tr>
						<td>Pins (USD) </td>
						<td align="right">${{ clubname.pins | currency }}</td>
					</tr>
					<tr>
						<td>Payments to date (USD) </td>
						<td align="right">(${{ clubname.paid | currency }})</td>
					</tr>
					<tr>
						<td><strong>Amount due </strong></td>
						<td align="right"><strong>${{ maxpayment | currency }}</strong></td>
					</tr>
				</table>
				<div>
					<div class="form-radio">
						<input type="radio" id="payfull" value="1" v-model="picked">
						<label for="payfull">Pay full amount</label>
					</div>
					<div class="form-radio">
						<input type="radio" id="paypart" value="2" v-model="picked">
						<label for="paypart">Pay other amount</label>
						<input type="number" min="100" step="0.1" class="form-control" v-show="picked == 2"
							v-model="amountdue" :max="maxpayment" placeholder="Enter payment amount" />
						<small class="error" v-show="picked == 2">{{ payamounterror }}</small>
					</div>
				</div>

				<div>
					<div>
						<br />
						<h2>Billing Information <small>(as appears on credit card)</small></h2>
					</div>
					<div>
						<input type="text" class="form-control" v-model="fullname" placeholder="Full name" />
					</div>
					<div>
						<input type="email" class="form-control" v-model="emailaddress" placeholder="Email address" />
					</div>
					<div>
						<input type="number" step="1" class="form-control" v-model="phone" placeholder="Phone number" />
					</div>
					<div>
						<button type="button" class="btn btn-primary" :disabled="!isformvalid"
							@click="btncontinueClick">Continue</button>
					</div>

				</div>

			</div>

			<div v-show="showstripe && clubname != 0">
				<div>
					<h2>Credit Card</h2>
				</div>
				<div class="alert alert-primary" role="alert">
					<h3>Notice to Scotiabank Credit Card Customers</h3><br />&nbsp;
					<h5 class="mb-5">Please contact the bank's credit card center before attempting to make a payment
						using
						your credit card.</h5><br />&nbsp;
				</div>
				<p align="right"></p>
				<table class="summary-table">
					<tr>
						<td>Payment amount</td>
						<td align="right">${{ amountdue | currency }}</td>
					</tr>
					<tr>
						<td>Processing fees</td>
						<td align="right">${{ fees | currency }}</td>
					</tr>
					<tr>
						<td><strong>Total</strong><br><small>Your payment details will be processed by Soca Islands LLC
								via
								Stripe.com.</small></td>
						<td align="right"><strong>${{ (stripeamount / 100) | currency }}</strong></td>
					</tr>
				</table>
				<div class="form-radio">
					<input type="checkbox" id="terms" value="true" v-model="agreeterms">
					<label for="terms">I have read and agree to the terms and conditions.</label>
				</div>
				<div>
					<input type="hidden" value="" name="stripeamount" id="stripeamount" v-model="stripeamount">
					<fieldset class="form-group">
						<div class="form-row col-12 form-control">
							<label for="card-element" style="width:100%;">

							</label>
							<!--input type="text" v-model="fullname" class="form-control" style="margin-bottom:5px;" placeholder="Cardholder's full name"/-->

							<div id="card-element" class=""></div>

							<!-- Used to display form errors. -->
							<div id="card-errors" role="alert" style="color:#ff0000;"> </div>
						</div>
					</fieldset>
					<button type="button" :data-secret="clientsecret" id="card-button"
						class="btn btn-primary btn-block btn-lg mb-2" :disabled="!ispaymentformvalid || isbusy">
						<span v-show="!isbusy">Pay Now</span>
						<span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status"
								aria-hidden="true"></span> Please wait ...</span>
					</button>
					<button type="button" class="btn back-button" @click="btnbackClick">Go back...</button>
				</div>
			</div>
		</form>
		<div align="center" v-show="activetab == 'thankyou'">
			<h2>Thank you for your payment!</h2>
			<a href="/" class="btn btn-primary" style="padding:10px 20px; text-decoration:none;">Continue</a>
		</div>

	</div>
</template>
<script type="text/javascript" src="//js.stripe.com/v3/"></script>
<script>
const fb = require('../firebaseConfig.js')
export default {
  name: 'Dues',
  props: {
    msg: String
  },
  filters: {
      currency (value) {
          if (!value) value = 0
          value = Number(value)
          return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  computed:{
      isformvalid(){
          //return true
          if(this.picked==2 && this.amountdue > this.maxpayment) return false
          if(this.picked==2 && this.amountdue < 100) return false
          if(!this.fullname) return false
          if(!this.emailaddress) return false
          if(!this.phone) return false
          return true
      },
      ispaymentformvalid(){
          if(!this.agreeterms) return false
          return true
      },
      payamounterror(){

          if(this.picked==2 && this.amountdue < 100){

            return 'Error: Invalid payment amount'
          }

          if(this.picked==2 && this.amountdue > this.maxpayment){
            return 'Error: You cannot pay more than you currently owe.'
          }

          return ''
      },
      /*fees(){
          return (((this.amountdue + 0.35)/0.945)-this.amountdue)
      }*/

  },
  data (){ 
      return {
          dues:27,
          picked:'',
          clubname:'0',
          payamount:'',
          amountdue: 540,
          maxpayment: 540,
          fullname:'',
          emailaddress:'',
          phone:'',
          stripeamount:0,
          fees:0,
          isbusy:false,
          clientsecret:'',
          showstripe:false,
          agreeterms:false,
          clientsecret:'',
          pytintent:'',
          API_URL:'https://dues.rotarydistrict7030.org/api',
          clubs:[],
          activetab:'form',
          ordernum:'',
          seed:[],
      } 
  },
  methods:{
    clubnameChange(){
        this.showstripe = false
        this.maxpayment = this.amountdue = Number(this.clubname.amt)
    },
    btnbackClick(){
        this.showstripe = false
    },
    btncontinueClick(){
        this.amountdue = (this.picked==2) ? Number(this.amountdue) : Number(this.maxpayment)
        this.stripeamount = Math.trunc(((this.amountdue + 0.30)/0.95)*100)
        this.fees = ((this.amountdue + 0.30)/0.95)-this.amountdue
        this.showstripe = true
        let $this = this
        const stripe = Stripe('pk_live_NO5i9A0rJetFGqZ99mLPeSpP') //pk_test_itCmziCcsavym8T71gROTOhj  pk_live_NO5i9A0rJetFGqZ99mLPeSpP

        var elements = stripe.elements()

        let style = {
            base: {
                color: '#32325d',
                fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '1.15em',
                '::placeholder': {
                      color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }

        // Create an instance of the card Element.
        let card = elements.create('card', {style: style})

        // Add an instance of the card Element into the `card-element` <div>.
        card.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        let displayError = document.getElementById('card-errors')
        card.addEventListener('change', function(event) {
            $this.isbusy = false
            if (event.error) {
                displayError.textContent = event.error.message
            } else {
                displayError.textContent = ''
            }
        })

        let submitButton = document.getElementById('card-button')

        submitButton.addEventListener('click', async () =>{
            this.isbusy = true
            if(this.fullname==''  || this.fullname.length<4){
                this.isbusy = false
                displayError.textContent = "Cardholder's name is missing"
                return false
            }

            let orderDesc = "Payment of Rotary District 7030 Dues"

            if(this.clientsecret==''){
                this.ordernum = Math.floor(Date.now()/100)

                //get stripe payment intent
                //console.log("stripe amount: ", this.stripeamount)
                if(this.stripeamount <1) return false

                let striperesponse = await fetch(this.API_URL+'/stripe/create/'+this.ordernum+'!'+this.stripeamount+'!'+encodeURI(this.fullname)+'!'+encodeURI(this.clubname.clubname)+'!'+encodeURI(this.emailaddress)+'!'+encodeURI(Number(this.amountdue))).then((response)=> {
                  return response.json()
                })

                this.clientsecret = striperesponse.client_secret
                this.pytintent = striperesponse.payment_intent
                //console.log(this.clientsecret, " / ", this.pytintent)
            }


            stripe.confirmCardPayment(this.clientsecret,
                {
                  payment_method: {
                  card: card,
                  billing_details: {
                    name: this.fullname,
                    phone: this.phone,
                    email: this.emailaddress,
                  },
                },
            }).then(async (result) =>{
                if (result.error) {
                    document.getElementById('card-errors').textContent = result.error.message + " Please contact your bank for further information." 
                    this.isbusy = false
                } else{
                    //console.log('The payment has been processed!')
                    //this.amountdue

                    fb.clubsCollection.doc(this.clubname.id).update({
                        dues: fb.firebase.firestore.FieldValue.increment((-1)*this.amountdue),
                        paid: fb.firebase.firestore.FieldValue.increment(this.amountdue),
                    })

                    let emailaddresses = 'drr@rotaract7030.org, secretary@rotaract7030.org, districtsecretary7030@gmail.com, treasurer@rotaract7030.org, rolinzoh@gmail.com, chatheraadrai@gmail.com'
                    if(this.clubname.emails.president) emailaddresses +=  ", " + this.clubname.emails.president
                    if(this.clubname.emails.secretary) emailaddresses +=  ", " + this.clubname.emails.secretary
                    if(this.clubname.emails.treasurer) emailaddresses += ", " + this.clubname.emails.treasurer

                    //console.log('email', emailaddresses)

                    let data = {
                        club:{clubemails:emailaddresses, clubname:this.clubname.clubname, dues:this.clubname.amt},
                        payment:{orderid: this.ordernum, paid:this.amountdue, payor: this.fullname + ' - ' +  this.emailaddress + ' - ' + this.phone }
                    }

                    await fetch(this.API_URL+'/sendemail',{
                        method: 'POST', 
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin', 
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify(data)
                    }).then((response)=> {
                        
                        return response.json()

                    })

                    this.clientsecret = ''
                    this.isbusy = false
                    this.activetab = "thankyou"


                }
            })

        })
    }
  },
  mounted(){
        fb.rtClubsCollection.orderBy("clubname", "asc").onSnapshot((querySnapshot)=>{
            let i=0
            this.clubs.length=0
            querySnapshot.forEach((doc)=>{
                this.clubs.push({id:doc.id, data:doc.data()})
                i++
            })
        })



        this.seed = [
    {
        "clubname": "RTC Antigua",
        "president": "arielderrick31@hotmail.com ",
        "secretary": "martinalewis007@outlook.com",
        "treasurer": "rotaractclubantigua@gmail.com",
        "clubsize": "31",
        "charge": "15",
        "actual_dues": "465",
        "arrears": "0",
        "pins": "0",
        "dues": "465"
    },
    {
        "clubname": "RTC Aruba",
        "president": "karelarends22@gmail.com",
        "secretary": "info@rotaractaruba.com",
        "treasurer": "info@rotaractaruba.com",
        "clubsize": "14",
        "charge": "15",
        "actual_dues": "210",
        "arrears": "0",
        "pins": "0",
        "dues": "210"
    },
    {
        "clubname": "RTC Barbados West",
        "president": "rbellamy246@gmail.com",
        "secretary": "yearwood9@hotmail.com",
        "treasurer": "rotaractbarbadoswestpresident@gmail.com",
        "clubsize": "9",
        "charge": "15",
        "actual_dues": "135",
        "arrears": "135",
        "pins": "0",
        "dues": "270"
    },
    {
        "clubname": "RTC South Barbados",
        "president": "reshomer2021@gmail.com",
        "secretary": "rotaractsouthbarbados@gmail.com",
        "treasurer": "treasurer.rcsouthbds@gmail.com",
        "clubsize": "20",
        "charge": "15",
        "actual_dues": "300",
        "arrears": "0",
        "pins": "0",
        "dues": "300"
    },
    {
        "clubname": "RTC Barbados",
        "president": "shaunte_dm_carter@hotmail.com",
        "secretary": "baileyzarry18@gmail.com",
        "treasurer": "rotaractbarbados@gmail.com",
        "clubsize": "32",
        "charge": "15",
        "actual_dues": "480",
        "arrears": "0",
        "pins": "0",
        "dues": "480"
    },
    {
        "clubname": "RTC U.W.I. Cave Hill Barbados",
        "president": "Summergoodingedghill@gmail.com ",
        "secretary": "kendrabynoe@gmail.com ",
        "treasurer": "rotaract@mycavehill.uwi.edu",
        "clubsize": "16",
        "charge": "12",
        "actual_dues": "192",
        "arrears": "0",
        "pins": "0",
        "dues": "192"
    },
    {
        "clubname": "RTC Curaçao",
        "president": "presidentrotaractcur@gmail.com",
        "secretary": "secretaryrotaractcur@gmail.com",
        "treasurer": "kristysenior91@gmail.com",
        "clubsize": "20",
        "charge": "15",
        "actual_dues": "300",
        "arrears": "465",
        "pins": "0",
        "dues": "765"
    },
    {
        "clubname": "RTC Roseau",
        "president": "Jamesiyankahelena@gmail.com ",
        "secretary": "Chelseabalthazar@gmail.com ",
        "treasurer": "Rotaractclubofroseau@gmail.com ",
        "clubsize": "16",
        "charge": "15",
        "actual_dues": "240",
        "arrears": "0",
        "pins": "0",
        "dues": "240"
    },
    {
        "clubname": "RTC Ile de Cayenne",
        "president": "murin535@gmail.com ",
        "secretary": "stephanie.desir-guyane@outlook.fr",
        "treasurer": "clubrotaract.idc@gmail.com",
        "clubsize": "8",
        "charge": "15",
        "actual_dues": "120",
        "arrears": "0",
        "pins": "0",
        "dues": "120"
    },
    {
        "clubname": "RTC Grenada",
        "president": "president@rotaractgrenada.com",
        "secretary": "secretary@rotaractgrenada.com",
        "treasurer": "president@rotaractgrenada.com",
        "clubsize": "19",
        "charge": "15",
        "actual_dues": "285",
        "arrears": "0",
        "pins": "0",
        "dues": "285"
    },
    {
        "clubname": "RTC Grenada East",
        "president": "anissa3416@gmail.com",
        "secretary": "npierre1224@gmail.com",
        "treasurer": "rotaractclubgrenadaeast@gmail.com",
        "clubsize": "14",
        "charge": "15",
        "actual_dues": "210",
        "arrears": "210",
        "pins": "0",
        "dues": "420"
    },
    {
        "clubname": "RTC Pointe-à-Pitre",
        "president": "président@clubrotaractpap.org",
        "secretary": "secrétaire@clubrotaractpap.org",
        "treasurer": "clubrotaract971@gmail.com",
        "clubsize": "3",
        "charge": "15",
        "actual_dues": "45",
        "arrears": "0",
        "pins": "0",
        "dues": "45"
    },
    {
        "clubname": "RTC Corriverton",
        "president": "ramonaameir2018@gmail.com",
        "secretary": "anand.jaundoo@gmail.com",
        "treasurer": "ramonaameir2018@gmail.com",
        "clubsize": "10",
        "charge": "15",
        "actual_dues": "150",
        "arrears": "240",
        "pins": "0",
        "dues": "390"
    },
    {
        "clubname": "RTC University of Guyana",
        "president": "Jemmie2461@gmail.com",
        "secretary": "jayapersaud.2000@gmail.com",
        "treasurer": "Rcugdistrict7030@gmail.com",
        "clubsize": "22",
        "charge": "12",
        "actual_dues": "264",
        "arrears": "0",
        "pins": "0",
        "dues": "264"
    },
    {
        "clubname": "RTC Georgetown",
        "president": "anansa.swain@gmail.com",
        "secretary": "liamwong21@gmail.com",
        "treasurer": "rcgpresident592@gmail.com",
        "clubsize": "28",
        "charge": "15",
        "actual_dues": "420",
        "arrears": "0",
        "pins": "0",
        "dues": "420"
    },
    {
        "clubname": "RTC Georgetown Central",
        "president": "moniquerenewilliams@gmail.com",
        "secretary": "jconway.rotaract@gmail.com",
        "treasurer": "rotagtcentral@gmail.com",
        "clubsize": "35",
        "charge": "15",
        "actual_dues": "525",
        "arrears": "0",
        "pins": "0",
        "dues": "525"
    },
    {
        "clubname": "RTC New Amsterdam",
        "president": "ellisabjonas@gmail.com ",
        "secretary": "gahwonag@gmail.com",
        "treasurer": "na.rotaract.president@gmail.com",
        "clubsize": "26",
        "charge": "15",
        "actual_dues": "390",
        "arrears": "0",
        "pins": "0",
        "dues": "390"
    },
    {
        "clubname": "RTC Stabroek",
        "president": "sharleneneeranjan@yahoo.com",
        "secretary": "khriztianomac@gmail.com",
        "treasurer": "stabroekrotaractclub@yahoo.com",
        "clubsize": "15",
        "charge": "15",
        "actual_dues": "225",
        "arrears": "0",
        "pins": "0",
        "dues": "225"
    },
    {
        "clubname": "RTC Lamentin",
        "president": "Vanille.manneville@wanadoo.fR",
        "secretary": "rantin.Alexandre.w972@gmail.com",
        "treasurer": "rotaractclublamentin@gmail.com",
        "clubsize": "16",
        "charge": "15",
        "actual_dues": "240",
        "arrears": "615",
        "pins": "0",
        "dues": "855"
    },
    {
        "clubname": "RTC Montserrat",
        "president": "vovtmark@gmail.com",
        "secretary": "bryanshsnia1@gmail.com",
        "treasurer": "vovtmark@gmail.com",
        "clubsize": "16",
        "charge": "15",
        "actual_dues": "240",
        "arrears": "0",
        "pins": "0",
        "dues": "240"
    },
    {
        "clubname": "RTC St. Kitts",
        "president": "santhaboon1@gmail.com",
        "secretary": "rianna.m@outlook.com",
        "treasurer": "rotaractclubsk@gmail.com",
        "clubsize": "9",
        "charge": "15",
        "actual_dues": "135",
        "arrears": "524",
        "pins": "0",
        "dues": "659"
    },
    {
        "clubname": "RTC Nevis",
        "president": " clairerobo67@gmail.com",
        "secretary": " gterisha@gmail.com",
        "treasurer": "nevisrotaractclub@gmail.com",
        "clubsize": "12",
        "charge": "15",
        "actual_dues": "180",
        "arrears": "0",
        "pins": "0",
        "dues": "180"
    },
    {
        "clubname": "RTC St. Lucia",
        "president": "calixte.williams@gmail.com",
        "secretary": "enjn.marie@gmail.com",
        "treasurer": "rotaractclubslu@gmail.com",
        "clubsize": "9",
        "charge": "15",
        "actual_dues": "135",
        "arrears": "0",
        "pins": "0",
        "dues": "135"
    },
    {
        "clubname": "RTC St. Lucia South",
        "president": "myiapalton@gmail.com",
        "secretary": "Terrica.atkins9@gmail.com",
        "treasurer": "presidentrsslu@gmail.com",
        "clubsize": "9",
        "charge": "15",
        "actual_dues": "135",
        "arrears": "0",
        "pins": "0",
        "dues": "135"
    },
    {
        "clubname": "RTC Gros Islet",
        "president": "anwarntez@gmail.com",
        "secretary": "teffany.fletcher@gmail.com",
        "treasurer": "rotaractclubgi@gmail.com",
        "clubsize": "10",
        "charge": "15",
        "actual_dues": "150",
        "arrears": "180",
        "pins": "0",
        "dues": "330"
    },
    {
        "clubname": "RTC Kingstown",
        "president": "simonemdallen@gmail.com",
        "secretary": "B.antonia6398@gmail.com ",
        "treasurer": "svg.rotaract@gmail.com",
        "clubsize": "26",
        "charge": "15",
        "actual_dues": "390",
        "arrears": "498",
        "pins": "0",
        "dues": "888"
    },
    {
        "clubname": "RTC F.R.E.S.H.",
        "president": "fresh.rc.pres@gmail.com",
        "secretary": "fresh.rotaractclub@gmail.com",
        "treasurer": "fresh.rc.fin@gmail.com",
        "clubsize": "26",
        "charge": "15",
        "actual_dues": "390",
        "arrears": "0",
        "pins": "0",
        "dues": "390"
    },
    {
        "clubname": "RTC Genesis",
        "president": "president.rotaractgenesis@gmail.com",
        "secretary": "rotaract.clubgenesis@gmail.com",
        "treasurer": "president.rotaractgenesis@gmail.com",
        "clubsize": "29",
        "charge": "15",
        "actual_dues": "435",
        "arrears": "0",
        "pins": "0",
        "dues": "435"
    },
    {
        "clubname": "RTC Paramaribo",
        "president": "presidentrotaractparamaribo@gmail.com",
        "secretary": "secretaryrotaractparamaribo@gmail.com",
        "treasurer": "treasurerrotaractparamaribo@gmail.com",
        "clubsize": "34",
        "charge": "15",
        "actual_dues": "510",
        "arrears": "0",
        "pins": "0",
        "dues": "510"
    },
    {
        "clubname": "RTC Piarco (U.W.I.)",
        "president": "shaniseliz@gmail.com",
        "secretary": "shaniavsamaroo@gmail.com",
        "treasurer": "rotaractpiarco@gmail.com",
        "clubsize": "19",
        "charge": "15",
        "actual_dues": "285",
        "arrears": "0",
        "pins": "0",
        "dues": "285"
    },
    {
        "clubname": "RTC San Fernando South",
        "president": "Leonardojbb7@gmail.com",
        "secretary": "celinabaldeo@gmail.com",
        "treasurer": "rotaractclubofrcss@gmail.com",
        "clubsize": "15",
        "charge": "15",
        "actual_dues": "225",
        "arrears": "0",
        "pins": "0",
        "dues": "225"
    },
    {
        "clubname": "RTC Sangre Grande",
        "president": "simonfaith98@yahoo.com",
        "secretary": "ranasalim4002@gmail.com",
        "treasurer": "rotaract.sangregrande@gmail.com",
        "clubsize": "11",
        "charge": "15",
        "actual_dues": "165",
        "arrears": "0",
        "pins": "0",
        "dues": "165"
    },
    {
        "clubname": "RTC St. Augustine",
        "president": "tereiysaiphoo96@gmail.com",
        "secretary": "kandrewsrichards@hotmail.com",
        "treasurer": "tereiysaiphoo96@gmail.com",
        "clubsize": "12",
        "charge": "15",
        "actual_dues": "180",
        "arrears": "120",
        "pins": "0",
        "dues": "300"
    },
    {
        "clubname": "RTC Tobago",
        "president": "Shaleemafrank1998@yahoo.com",
        "secretary": "kayla.d12564@gmail.com",
        "treasurer": "bagorotaract@gmail.com",
        "clubsize": "22",
        "charge": "15",
        "actual_dues": "330",
        "arrears": "1050",
        "pins": "0",
        "dues": "1380"
    },
    {
        "clubname": "RTC Arima",
        "president": "kqhouse@gmail.com",
        "secretary": "secretaryrotaractclubofarima@gmail.com",
        "treasurer": "rotaractclubofarima@hotmail.com",
        "clubsize": "10",
        "charge": "15",
        "actual_dues": "150",
        "arrears": "0",
        "pins": "0",
        "dues": "150"
    },
    {
        "clubname": "RTC Central Port of Spain",
        "president": "ruqayyahscott@gmail.com",
        "secretary": "kiranmooteeram@gmail.com",
        "treasurer": "rotaractcpos@gmail.com",
        "clubsize": "26",
        "charge": "15",
        "actual_dues": "390",
        "arrears": "0",
        "pins": "0",
        "dues": "390"
    },
    {
        "clubname": "RTC Diego Martin",
        "president": "Avanellealexander@gmail.com",
        "secretary": "kiarareece14@gmail.com",
        "treasurer": "avanellealexander@gmail.com",
        "clubsize": "17",
        "charge": "15",
        "actual_dues": "255",
        "arrears": "0",
        "pins": "0",
        "dues": "255"
    },
    {
        "clubname": "RTC Felicity/ Charlieville",
        "president": "teja_persad@hotmail.com",
        "secretary": "malkasiewnarine@outlook.com ",
        "treasurer": "rotaractfelicitycharlieville@gmail.com",
        "clubsize": "16",
        "charge": "15",
        "actual_dues": "240",
        "arrears": "0",
        "pins": "0",
        "dues": "240"
    },
    {
        "clubname": "RTC Maraval",
        "president": "baptistewhitneyann@gmail.com ",
        "secretary": "rotamaraval@gmail.com",
        "treasurer": "rotamaravaltreasurer@gmail.com",
        "clubsize": "10",
        "charge": "15",
        "actual_dues": "150",
        "arrears": "0",
        "pins": "0",
        "dues": "150"
    },
    {
        "clubname": "RTC Penal",
        "president": "tiffanyeyadams@yahoo.com",
        "secretary": "tiffanyeyadams@yahoo.com",
        "treasurer": "rotaractpenal@gmail.com",
        "clubsize": "8",
        "charge": "15",
        "actual_dues": "120",
        "arrears": "0",
        "pins": "0",
        "dues": "120"
    },
    {
        "clubname": "RTC Port of Spain West",
        "president": "shenelleharris@gmail.com",
        "secretary": "secretary.rcposwest@gmail.com",
        "treasurer": "rotposwest@gmail.com",
        "clubsize": "7",
        "charge": "15",
        "actual_dues": "105",
        "arrears": "270",
        "pins": "0",
        "dues": "375"
    },
    {
        "clubname": "RTC San Fernando",
        "president": "charissadavid02@gmail.com",
        "secretary": "angelina.ali2001@gmail.com",
        "treasurer": "rotaractsfdo@gmail.com",
        "clubsize": "14",
        "charge": "15",
        "actual_dues": "210",
        "arrears": "0",
        "pins": "0",
        "dues": "210"
    },
    {
        "clubname": "RTC St. Augustine West",
        "president": "bridgettemaynard@ymail.com",
        "secretary": "kidenschaeregis@gmail.com",
        "treasurer": "rotaractsaw@yahoo.com",
        "clubsize": "16",
        "charge": "15",
        "actual_dues": "240",
        "arrears": "0",
        "pins": "0",
        "dues": "240"
    },
    {
        "clubname": "RTC Point Fortin",
        "president": "rotaractpresidentpointfortin@gmail.com",
        "secretary": "rotaractsecretarypointfortin@gmail.com ",
        "treasurer": "rotaractclubpointfortin@gmail.com",
        "clubsize": "10",
        "charge": "15",
        "actual_dues": "150",
        "arrears": "645",
        "pins": "0",
        "dues": "795"
    },
    {
        "clubname": "RTC Mount Hope",
        "president": " ramsumairzachary@gmail.com",
        "secretary": "",
        "treasurer": "",
        "clubsize": "33",
        "charge": "12",
        "actual_dues": "396",
        "arrears": "396",
        "pins": "0",
        "dues": "792"
    }
]

        /* for(var x=0; this.seed.length; x++){

            let treasurer = (this.seed[x].treasurer) ? this.seed[x].treasurer : ''
            let president = (this.seed[x].president) ? this.seed[x].president : ''
            let secretary = (this.seed[x].secretary) ? this.seed[x].secretary : ''
            
            fb.rtClubsCollection.add({
                clubname: this.seed[x].clubname,
                clubsize: Number(this.seed[x].clubsize) > 0 ? Number(this.seed[x].clubsize) : "TBA",
                clubtype: Number(this.seed[x].charge) === 12 ? "SB" : "CB",
                arrears: Number(this.seed[x].arrears),
                current: Number(this.seed[x].actual_dues),
                charge: Number(this.seed[x].charge),
				pins: Number(this.seed[x].pins),
                dues: Number(this.seed[x].dues),
				actual_dues: Number(this.seed[x].actual_dues),
                paid: 0,
                emails:{president, secretary, treasurer}

            })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            }) 
            
        }  */

        



    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.summary-table td {
	text-align: left;
	border-bottom: 1px solid #f1f1f1;
	padding: 10px 0;
}

.summary-table td:nth-child(2) {
	text-align: right;
}

.payment-form {
	width: 50%;
	margin: auto;
	text-align: left;
	padding: 0;
	padding-bottom: 60px;
}

.form-control {
	display: block;
	width: 97%;
	margin: 5px 0;
	padding: 15px 10px;
	font-size: 1em;
	border: 1px solid #f1f1f1;
}

.form-radio {
	width: 97%;
	margin: 5px 0;
	padding: 10px;
	font-size: 1em;
	border: 1px solid #f1f1f1;
}

.btn {
	width: 100%;
	padding: 15px 0;
	border: 0;
	margin: 10px 0;
	font-size: 1.01em;
	border-radius: 0;
}

.btn-primary {
	background: #005daa;
	color: #fff;
}

.back-button {
	color: #005daa;
	padding: 15px;
	border: 1px solid #005daa;
	margin-top: 15px;
	background: #fff;
}

.error {
	color: #f00;
}

.logo {
	max-width: 200px;
	height: auto;
}

.alert-primary {
	color: #004085;
	background-color: #cce5ff;
	border-color: #b8daff;
}

.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: .25rem;
}

.h3,
h3 {
	font-size: 1.75rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: .5rem;
	font-weight: 500;
	line-height: 1.2;
	margin-top: 0;
	padding-top: 0;
}

.h5,
h5 {
	font-size: 1.25rem;
}

select .form-control {
	width: 100%;
}

table {
	width: 100%;
	margin: 10px 0;
}

table .no-border {
	border: 0;
}

h3 {
	margin: 40px 0 0;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}

small {
	font-size: 14px;
}

fieldset {
	border: 0;
	padding: 0;
}

:disabled {
	background: #999;
}

@media only screen and (max-width: 780px) {
	.payment-form {
		width: 80%;
	}
}
</style>
